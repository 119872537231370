// JS shared across all pages

if (location.hostname == 'localhost') {
    // replace anchor tags with .html links
    let anchors = document.querySelectorAll('a');

    anchors.forEach((anchor) => {
        if (anchor.href) {
            if (anchor.href.includes('localhost')) {
                anchor.href = `${anchor.href}.html`;
            }
        }
    })
}

// Write indexOf if it doesn't exist
// (This is for IE 💩)
if (!Array.prototype.indexOf) {
    Array.prototype.indexOf = function(obj, start) {
        for (var i = (start || 0), j = this.length; i < j; i++) {
            if (this[i] === obj) {
                return i;
            }
        }
        return -1;
    }
}


const console_style = "font-size: 24px; color:black; font-weight: bold; font-family:-apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, “Fira Sans”, “Droid Sans”, “Helvetica Neue”, Arial, sans-serif;"
console.log("%cHowdy 👋", console_style)


// Get URL PARAMS

window.getUrlParam = function(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}